import { format } from 'date-fns'
import { enUS, srLatn } from 'date-fns/locale'

const dateLocales = {
  en_US: enUS,
  sr_RS: srLatn
}

const getCurrency = function (amount, currency, locale) {
  const numberLocale = locale === 'en_US' ? 'en-US' : 'sr-Latn'
  const currencyString =
    Number(amount.toFixed(2)).toLocaleString(
      numberLocale,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: 'currency',
        currency
      }
    )
  return currencyString
}
const getMoney = function (amount, locale) {
  const numberLocale = locale === 'en_US' ? 'en-US' : 'sr-Latn'
  const moneyString =
    Number(amount.toFixed(2)).toLocaleString(
      numberLocale,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }
    )
  return moneyString
}
const getBudget = function (model, locale) {
  return getCurrency(
    model.fields.eoi_budget,
    model.fields.eoi_budget_currency,
    locale
  )
}
const getDate = function (dateString, locale) {
  let formatStr = 'do MMM yyyy'
  if (locale === 'sr_RS') {
    formatStr += '.'
  }
  return format(new Date(dateString), formatStr, { locale: dateLocales[locale] })
}
const getTime = function (dateString, locale) {
  let formatStr = 'HH:mm OOO'
  if (locale === 'sr_RS') {
    formatStr += '.'
  }
  return format(new Date(dateString), formatStr, { locale: dateLocales[locale] })
}
export {
  getCurrency,
  getMoney,
  getBudget,
  getDate,
  getTime
}
