import { render, staticRenderFns } from "./index.vue?vue&type=template&id=a5c8fb62&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=a5c8fb62&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5c8fb62",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageSection: require('/workspace/components/global/PageSection.vue').default,PageWrapper: require('/workspace/components/global/PageWrapper.vue').default})
