//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDate, getTime, getBudget } from '~/utils/formatting'

export default {
  auth: 'guest',
  data () {
    return {
      eois: null,
      eoisLoaded: false,
      eoisError: false,
      tableFields: [
        { key: 'status', label: this.$t('eoi.status.nameLabel') },
        { key: 'title', label: this.$t('eoi.titleLabel') },
        // { key: 'budget', label: this.$t('eoi.budgetLabel') },
        { key: 'location', label: this.$t('eoi.LocationLabel') },
        { key: 'opening_date', label: this.$t('eoi.openDateLabel') },
        { key: 'closing_date', label: this.$t('eoi.closeDateLabel') },
        // { key: 'services', label: this.$t('eoi.servicesHeading') },
        { key: 'actions', label: '' }
      ]
    }
  },
  async fetch () {
    // await this.$axios.get('sanctum/csrf-cookie')
    let result = null
    try {
      result = await this.$axios.get('/api/data/eoi?locale=' + this.locale + '&sort=-fields.eoi_close_datetime')
    } catch (e) {
      this.eoisError = true
      this.eoisLoaded = true
    }
    this.eois = result.data.data

    //TEMP
    // this.eois = [];

    this.eoisLoaded = true
  },
  computed: {
    vendorRegistrationUrl () {
      return this.localePath('/en/vendor-registration')
    },
    locale () {
      return this.$i18n.locale
    },
    formattedItems () {
      return this.eois.map(this.formatItem)
    },
    itemsFilter () {
      return { }
    },
    verified () {
      return this.$auth.user && this.$auth.user.email_verified_at
    },
    approved () {
      return this.$auth.user?.company?.status === 'approved'
    },
    eoiEnabled () {
      return this.$config.eoiEnabled
    }
  },
  mounted () {
    if (this.$route.query.signedup) {
      this.signupWelcome = true
    }
    if (this.$route.query.verified) {
      this.verifiedEmail = true
      delete this.$route.query.verified
    }
  },
  methods: {
    formatItem (item) {
      const services = item.fields.eoi_services
      return {
        id: item.id,
        status: new Date(item.fields.eoi_open_date) < new Date() && new Date() < new Date(item.fields.eoi_close_datetime) ? { color: 'success', label: this.$t('eoi.status.openLabel') } : { color: 'gray', label: this.$t('eoi.status.closedLabel') },
        title: `${item.fields.eoi_title}`,
        description: `${item.fields.eoi_description}`,
        budget: this.getBudget(item),
        location: `${item.fields.eoi_location}`,
        opening_date: item.fields.eoi_open_date ? this.getDate(item.fields.eoi_open_date || null) : '/',
        closing_date: item.fields.eoi_close_datetime ? this.getDate(item.fields.eoi_close_datetime || null) : '/',
        services: services ? services.map(s => s.fields.service_name) : [],
        busy: false
      }
    },
    getItemUrl (item) {
      return this.localePath(`/en/eoi/${item.id}`)
    },
    stripHTML(html){
      let doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    },
    filterItems (item, filter) {
      return true
    },
    getBudget (item) {
      if (!item.fields.eoi_budget) {
        return '/'
      }
      return getBudget(item, this.locale)
    },
    getDate (dateString) {
      return getDate(dateString, this.locale)
    },
    getTime (dateString) {
      return getTime(dateString, this.locale)
    }
  }
}
